import localFont from 'next/font/local';
import { Secular_One, Alef } from 'next/font/google';

export const fontToFontObject = (font: Fonts) => {
    switch (font) {
        case Fonts.NotoSans:
            return NotoSansFont;
        case Fonts.OpenSans:
            return OpenSansFont;
        case Fonts.SecularOne:
            return SecularOneFont;
        case Fonts.Alef:
            return AlefFont;
    }
};

export enum Fonts {
    OpenSans = 'opensans-condensed',
    NotoSans = 'notosans-semicondensed',
    SecularOne = 'secular-ono',
    Alef = 'alef'
}

export enum FontWeights {
    Light = 300,
    Normal = 400,
    SemiBold = 600,
    Bold = 700,
    ExtraBold = 800
}

export const OpenSansFont = localFont({
    src: [
        {
            path: 'fonts/opensans-condensed/OpenSansHebrewCondensed-Light.ttf',
            weight: '200',
            style: 'light'
        },
        {
            path: 'fonts/opensans-condensed/OpenSansHebrewCondensed-Regular.ttf',
            weight: '400',
            style: 'normal'
        },
        {
            path: 'fonts/opensans-condensed/OpenSansHebrewCondensed-Bold.ttf',
            weight: '500',
            style: 'bold'
        }
    ]
});

export const NotoSansFont = localFont({
    src: [
        {
            path: 'fonts/notosans-semicondensed/NotoSansHebrew_SemiCondensed-Light.ttf',
            weight: '200',
            style: 'light'
        },
        {
            path: 'fonts/notosans-semicondensed/NotoSansHebrew_SemiCondensed-Medium.ttf',
            weight: '300',
            style: 'medium'
        },
        {
            path: 'fonts/notosans-semicondensed/NotoSansHebrew_SemiCondensed-Regular.ttf',
            weight: '400',
            style: 'normal'
        },
        {
            path: 'fonts/notosans-semicondensed/NotoSansHebrew_SemiCondensed-Bold.ttf',
            weight: '600',
            style: 'bold'
        }
    ]
});

export const SecularOneFont = Secular_One({
    subsets: ['hebrew'],
    weight: '400'
});

export const AlefFont = Alef({
    subsets: ['hebrew'],
    weight: '400'
});
