import { styled } from 'styled-components';
import Lottie from 'lottie-react';
import AnimationData from '@/components/animations/loader.json';

const PageLoader = () => (
    <Container>
        <Overlay />
        <StyledLottiePlayer animationData={AnimationData} className="flex justify-center items-center" loop={true} />
    </Container>
);

export default PageLoader;

const Container = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 50;
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
`;

const StyledLottiePlayer = styled(Lottie)`
    position: absolute;
    top: 30%;
    width: 250px;
`;
