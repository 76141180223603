import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import PageLoader from '@/components/PageLoader';
import { useRouter } from 'next/router';
import { useScroll } from '@/components/providers/ScrollProvider';

interface IContextProps {
    showLoader: () => void;
    hideLoader: () => void;
    isLoading: boolean;
}

export const LoaderContext = createContext<IContextProps>({} as IContextProps);
LoaderContext.displayName = 'LoaderContext';

export const useLoader = () => {
    return useContext(LoaderContext);
};

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [shouldShowLoader, setShouldShowLoader] = useState(false);
    const { disableScroll, enableScroll } = useScroll();

    useEffect(() => {
        if (isLoading) {
            disableScroll();
        } else {
            enableScroll();
        }
    }, [isLoading, disableScroll, enableScroll]);

    useEffect(() => {
        const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
            if (!shallow) {
                setIsLoading(true);
            }
        };

        const handleRouteChangeComplete = (url: string, { shallow }: { shallow: boolean }) => {
            if (!shallow) {
                setIsLoading(false);
            }
        };

        router.events.on('routeChangeStart', handleRouteChange);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router]);

    const showLoader = useCallback(() => {
        setIsLoading(true);
    }, []);

    const hideLoader = useCallback(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (isLoading) {
            timeout = setTimeout(() => setShouldShowLoader(true), 100);
        } else {
            setShouldShowLoader(false);
        }

        return () => clearTimeout(timeout);
    }, [isLoading]);

    return (
        <LoaderContext.Provider value={{ showLoader, hideLoader, isLoading }}>
            {shouldShowLoader && <PageLoader />}
            {children}
        </LoaderContext.Provider>
    );
};
