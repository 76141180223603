import { styled } from 'styled-components';
import HNButton from './HNButton';
import { useRouter } from 'next/router';
import { Image } from '@nextui-org/react';
import { Fonts } from '@/styles/fonts';
import HNText from './HNText';
import { useSession } from 'next-auth/react';
import { colors } from '@/styles/colors';
import config from '@/lib/config';

const BottomBar = () => {
    const router = useRouter();
    const { status } = useSession();

    const onContactClick = () => {
        router.push('/contact');
    };

    const onAboutClick = () => {
        router.push('/about');
    };

    const onProjectsClick = () => {
        router.push('/projects');
    };

    const onCatalogClick = () => {
        router.push('/catalog');
    };

    const onLoginClick = () => {
        router.push('/auth/signin');
    };

    const onHomeClick = () => {
        router.push('/');
    };

    const onPrivacyPolicyClick = () => {
        router.push('https://static.hazmanina.co.il/privacy_policy.pdf');
    };

    const onTermsOfUseClick = () => {
        router.push('https://static.hazmanina.co.il/terms_of_use.pdf');
    };

    return (
        <Container>
            <InnerContainer>
                <UpperContainer>
                    <RightContainer>
                        <Column>
                            <HNButton font={Fonts.Alef} onClick={onCatalogClick} style="sm-bottom-nav">
                                עצבו הזמנה
                            </HNButton>
                            {status === 'authenticated' ? (
                                <HNButton font={Fonts.Alef} onClick={onProjectsClick} style="sm-bottom-nav">
                                    העיצובים שלי
                                </HNButton>
                            ) : (
                                <HNButton font={Fonts.Alef} onClick={onLoginClick} style="sm-bottom-nav">
                                    התחברות
                                </HNButton>
                            )}
                            <HNButton font={Fonts.Alef} onClick={onContactClick} style="sm-bottom-nav">
                                צור קשר
                            </HNButton>
                        </Column>
                        <Column>
                            <HNButton font={Fonts.Alef} onClick={onAboutClick} style="sm-bottom-nav">
                                אודות
                            </HNButton>
                            <HNButton font={Fonts.Alef} onClick={onTermsOfUseClick} style="sm-bottom-nav">
                                תנאי שימוש
                            </HNButton>
                            <HNButton font={Fonts.Alef} onClick={onPrivacyPolicyClick} style="sm-bottom-nav">
                                מדיניות פרטיות
                            </HNButton>
                        </Column>
                    </RightContainer>
                    <LeftContainer>
                        <InnerButtonContainer onClick={onHomeClick}>
                            <StyledLogo src="/smiling-letter.png" alt="hazmanina" width={100} height={100} />
                            <StyledLogoText font={Fonts.SecularOne}>הזמנינא</StyledLogoText>
                        </InnerButtonContainer>
                    </LeftContainer>
                </UpperContainer>
                <BottomContainer>
                    <StyledCopyrightText>© 2024 hazmanina.co.il</StyledCopyrightText>
                </BottomContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    z-index: 20;
    width: inherit;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-item: center;

    background-color: ${colors.primary};
    height: ${config.ui.bottomBarHeight}px;
    bottom: 0px;
    color: white;

    @media screen and (min-width: 1800px) {
        border-radius: 0 0 6px 6px;
    }

    @media screen and (max-width: 900px) {
        height: 300px;
    }
`;

const InnerContainer = styled.div`
    width: 75%;

    margin-block-start: 15px;
    margin-block-end: 0px;
    margin-inline-start: 50px;
    margin-inline-end: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;
`;

const LeftContainer = styled.div`
    width: 33%;
    height: 100%;
`;

const RightContainer = styled.div`
    width: 33%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    gap: 10px;

    @media screen and (max-width: 900px) {
        width: 90%;
        justify-content: space-around;
    }
`;

const Column = styled.div`
    height: 70%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 900px) {
        align-items: center;
        gap: 10px;
    }
`;

const UpperContainer = styled.div`
    width: 100%;
    height: 75%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    direction: rtl;

    gap: 10px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

const BottomContainer = styled.div`
    width: 100%;
    height: 20%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InnerButtonContainer = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
`;

const StyledLogo = styled(Image)`
    margin-block-end: 5px;
`;

const StyledLogoText = styled(HNText)`
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-inline-start: 10px;
    color: ${colors.secondary};
`;

const StyledCopyrightText = styled(HNText)`
    direction: ltr;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    color: ${colors.secondary};
`;

export default BottomBar;
