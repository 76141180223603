import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import useApollo from '@/apollo-client';
import NavigationBar from '@/components/NavigationBar';
import { GlobalStyles } from '@/styles/globals';
import Head from 'next/head';
import { styled } from 'styled-components';
import { NextUIProvider } from '@nextui-org/system';
import '@/styles/tailwind.css';
import BottomBar from '@/components/BottomBar';
import config from '@/lib/config';
import { useRouter } from 'next/router';
import { AuthPages } from '@/lib/types/types';
import { LoaderProvider } from '@/components/LoaderProvider';
import { ScrollProvider } from '@/components/providers/ScrollProvider';

const MyApp: React.FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
    const router = useRouter();
    const apolloClient = useApollo();

    useEffect(() => {
        if (![AuthPages.signIn, '/redirect'].includes(router.asPath)) {
            localStorage.setItem('lastVisitedPath', router.asPath);
            window.scrollTo(0, 0);
        }
    }, [router.asPath]);

    return (
        <>
            <Head>
                <title>הזמנינא</title>
                <link rel="icon" href="/smiling-letter.png" />
            </Head>
            <GlobalStyles />
            <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                <ApolloProvider client={apolloClient}>
                    <NextUIProvider>
                        <ScrollProvider>
                            <Container className="dark">
                                <InnerContainer>
                                    <NavigationBar />
                                    <ComponentContainer>
                                        <LoaderProvider>
                                            <Component {...pageProps} />
                                        </LoaderProvider>
                                    </ComponentContainer>
                                    <BottomBar />
                                </InnerContainer>
                            </Container>
                        </ScrollProvider>
                    </NextUIProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InnerContainer = styled.div`
    width: min(100vw, 1800px);
    min-height: 100vh;
    position: relative;
`;

const ComponentContainer = styled.div`
    margin-block-start: ${config.ui.navbarHeight}px;
    height: calc(100% - ${config.ui.bottomBarHeight}px - ${config.ui.navbarHeight}px);
`;

export default MyApp;
