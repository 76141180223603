import { Fonts, fontToFontObject } from '@/styles/fonts';
import { ReactNode, forwardRef } from 'react';

type Props = {
    children: ReactNode;
    font?: Fonts;
    className?: string;
};

const HNText = forwardRef<HTMLDivElement, Props>(({ children, font = Fonts.OpenSans, className }: Props, ref) => {
    const fontClassName = fontToFontObject(font).className;

    return (
        <div ref={ref} dir="rtl" className={`${fontClassName} ${className}`}>
            {children}
        </div>
    );
});

HNText.displayName = 'HNText';

export default HNText;
